.uk-modal {
  h2 {
    color: $global-blue;

    span {
      color: $global-color;
      margin: 0px;
    }
  }
}
