.uk-button {
  border: 2px solid $global-black;
  padding: 0 15px;
  line-height: 30px;
  text-transform: uppercase !important;

  &.uk-button-default {
    border: 2px solid $global-black;

    &:hover {
      color: $global-white;
      border: 2px solid $global-blue;
      background: $global-blue;
      background-image: linear-gradient(40deg, rgba(43, 75, 214, 0.5) 0%, rgba(119, 144, 255, 0.5) 100%);
    }
  }

  &.uk-button-primary:hover {
    background: $global-blue;
    background-image: linear-gradient(40deg, rgba(43, 75, 214, 0.5) 0%, rgba(119, 144, 255, 0.5) 100%);
  }

  &.uk-button-small {
    border-radius: 35px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
  }

  &.uk-button-secondary {
    border: 2px solid $global-white;

    &:hover {
      border: 2px solid $global-grey-2;
    }
  }

  &.uk-button-text {
    border: 2px solid $global-black;
    padding: 5px 10px;
    display: block;
    margin: 60px auto;
  }

  @include hook-button;
}
