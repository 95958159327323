#stats {
  padding-bottom: 0;

  .stats-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 30px 0;
    background: linear-gradient(0deg, #373737 0%, black 100%);

    .stats-wrapper {
      width: 100%;
    }

    .stats-list {
      color: $global-white;
      display: flex;
      width: 100%;
      justify-content: space-between;

      p {
        margin: 0;
        font-family: $base-heading-font-family;
        text-transform: uppercase;
      }

      .stats-info {
        display: inline-block;
        padding: 20px 0;
        text-align: center;
        margin-top: 10px;
      }

      .stats-number {
        font-size: $global-large-font-size;
        font-weight: bold;
        width: 180px;
      }

      .stats-text {
        font-size: $global-small-font-size;
        color: $global-grey;
        font-weight: 500;
        line-height: 5px;
      }
    }

    @media screen and (max-width: $container-small-max-width) {
      .stats-list {
        flex-wrap: wrap;

        .stats-info {
          flex: 0 1 100%;
          text-align: center;
          margin: auto;

          .stats-number {
            width: auto;
          }
        }
      }
    }
  }
}
