#services-main {
  h6 {
    text-align: center;
  }

  .services-text {
    text-align: center;
  }

  .services-content-wrapper {
    display: flex;
    margin-top: 60px;

    .services-bar {
      flex: 0 1 30%;
      list-style: none;
      margin-right: 50px;
      border-right: 2px solid $global-muted-background;

      li {
        margin-bottom: 52px;

        span {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          display: inline-block;
          vertical-align: sub;
          background-color: $global-faded;

          &.icon-design {
            mask-image: url("theme/images/design.svg");
          }

          &.icon-development {
            mask-image: url("theme/images/development.svg");
          }

          &.icon-hosting {
            mask-image: url("theme/images/hosting.svg");
          }

          &.icon-misc {
            mask-image: url("theme/images/misc.svg");
          }
        }

        a {
          text-transform: uppercase;
          font-weight: 600;
          color: $global-faded;
          font-size: $global-medium-font-size;
          text-decoration: none;
          cursor: pointer;
          outline: 0;
        }

        &.active {
          a {
            color: $global-black;
          }

          span {
            background-color: $global-blue;
          }
        }
      }

      .services-blurb {
        color: $global-black;
        font-size: $global-small-font-size;
        margin: 0;

        a {
          color: $global-black;
          font-weight: bold;
        }

        &.upper {
          margin-top: 150px;
          max-width: 200px;
        }

        &.lower {
          max-width: 260px;
        }
      }
    }

    .services-content {
      flex: 0 1 60%;
      list-style: none;
      background-color: $global-muted-background;
      padding: 60px 20px;
      margin-top: 38px;
      position: relative;
      margin-left: 50px;
      max-height: 350px;
      box-sizing: border-box;

      .service-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 50px;
        justify-content: space-between;

        .service-heading {
          color: $global-white;
          background-color: $global-black;
          padding: 15px 50px;
          font-size: $global-medium-large-font-size;
          display: inline-block;
          position: absolute;
          top: -37px;
          left: -40px;
        }

        .services-features {
          list-style: outside;

          .service-feature {
            color: $global-blue;
            font-size: $global-medium-font-size;
            margin-bottom: 20px;

            span {
              color: $global-black;
              vertical-align: middle;
            }
          }
        }
      }

      .service-slide {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: $container-small-max-width) {
    .services-bar {
      display: none;
    }

    .services-content-wrapper {
      display: block;
      margin: 60px -30px -30px;

      .services-content {
        width: 100%;
        padding: 0 0 20px 0;
        margin: 0;
        max-height: initial;
        text-align: center;

        .service-info {
          text-align: center;
          display: block;
          padding: 0;

          * > {
            flex: 0 1 100%;
          }

          .service-heading {
            position: initial;
            width: 100%;
            text-align: center;
            padding: 15px 0;
          }

          .services-features {
            margin: 0;

            li {}
          }
        }
      }
    }
  }
}

@media screen and (max-width: $container-small-max-width) {
  .cta-bar {
    margin-top: 30px;
  }
}
