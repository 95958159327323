footer {
  background: $global-black;
  color: $global-white;
  font-size: $global-small-font-size;

  .logo-wrapper {
    flex-direction: column;
  }

  .formalities {
    font-size: 10px;
    margin-bottom: -20px;
    margin-top: 40px;

    li {
      display: inline-block;

      a {
        color: $global-white;
      }

      &:nth-child(2n):before {
        content: "|";
        display: inline-block;
        padding: 0 5px;
      }
    }

    &.mobile-formalities {
      display: none;
    }
  }

  .contact-info {
    li {
      display: inline-block;
    }

    a {
      color: $global-white;
    }
  }

  p {
    max-width: 355px;

    a {
      font-weight: bold;
      color: $global-white;
    }
  }

  .uk-flex {
    height: 200px;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: $global-white;
      }
    }
  }

  .mail, .phone {
    font-family: $base-heading-font-family;
  }

  @media screen and (max-width: $container-small-max-width) {
    padding-top: 20px;

    .uk-flex {
      height: auto;
      flex-wrap: wrap;

      > div {
        padding: 10px;
        flex: 0 1 100%;
        text-align: center;
        margin: auto;
      }
    }

    .formalities {
      display: none;

      &.mobile-formalities {
        display: block;
        margin: 0 auto;
        padding-bottom: 20px;
      }
    }
  }
}
