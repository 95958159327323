#services {
  height: 960px;

  .services-switcher {
    position: relative;
    z-index: 1;

    h1 {
      background: $global-black;
      color: $global-white;
      padding: 25px 58px 25px 45px;
      display: inline-block;
      vertical-align: top;
      margin-top: 25px;
    }

    h6 {
      color: $global-white;
    }

    .services-text {
      color: $global-white;
      padding: 0 5px;

      a {
        color: $global-white;
        font-weight: bold;
      }
    }

    .uk-subnav {
      justify-content: center;
      margin-top: 30px;

      li {
        position: relative;
        padding: 0 30px;
        font-size: $global-small-font-size;
        text-transform: uppercase;
        font-weight: 500;

        a {
          border-radius: 1px;
          padding: 3px 10px;
        }

        &:after {
          content: '';
          height: 12px;
          width: 1px;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -6px;
          background-color: $global-white;
        }

        &:last-child:after {
          background-color: transparent;
        }
      }
    }

    .switcher-content {
      position: relative;
    }

    .switcher-content-image {
      position: absolute;
      right: 25px;
      top: 77px;
      z-index: -1;
    }

    .switcher-content-info {
      display: inline-block;
      position: absolute;
      z-index: -1;
      left: 70px;
      top: 105px;

      .switcher-text {
        background: $global-white;
        font-size: $global-medium-font-size;
        padding: 66px 100px 40px 74px;
        line-height: 28px;
        max-width: 480px;
      }

      .muted {
        font-size: $global-small-font-size;
        color: $global-muted-color;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
      }
    }

    .switcher-cta {
      color: $global-white;
      font-size: $global-small-font-size;
      font-weight: 500;
      margin-top: 15px;

      button {
        margin-left: 30px;
      }
    }

    .mobile-services-top-wrapper {
      height: 600px;
    }

    .mobile-switcher {
      margin: 0;
      padding: 0;

      > li {
        display: none;

        &.active {
          display: block;
        }
      }
    }

    @media screen and (max-width: $container-small-max-width) {
      padding-top: 20px;
      display: none;

      h1 {
        font-size: $global-xlarge-font-size;
        text-align: center;
        margin-top: 0;

        img {
          vertical-align: bottom;
          margin-left: -80px;
        }
      }

      h6 {
        margin-top: 150px;
      }

      .services-text {
        text-align: center;
      }

      .uk-subnav {
        display: none;
      }

      .switcher-content {
        .switcher-text {
          text-align: center;
        }

        .switcher-content-image {
          top: 270px;
        }

        .switcher-cta {
          display: none;

          &.mobile-cta {
            display: block;
            color: $global-white;
            background: $global-blue;
            background-image: linear-gradient(40deg, rgba(43, 75, 214, 0.5) 0%, rgba(119, 144, 255, 0.5) 100%);
            padding: 15px 0;
          }
        }
      }

      .switcher-content-info {
        text-align: center;
        background: $global-white;
        position: static;
        display: block;

        .switcher-text {
          padding: 0;
          max-width: auto;
          width: 90%;
          margin: 50px auto;
        }
      }

      .switcher-content-image {
        position: static;

        img {
          width: 100%;
        }
      }
    }
  }

  .switcher-mobile-cta {
    display: none;
    color: $global-white;
    background: $global-blue;
    background-image: linear-gradient(40deg, rgba(43, 75, 214, 0.5) 0%, rgba(119, 144, 255, 0.5) 100%);
    padding: 0 15px;
    text-align: center;
    padding: 20px 15px;

    @media screen and (max-width: $container-small-max-width) {
      display: block;
    }
  }

  @media screen and (max-width: $container-small-max-width) {
    height: 1500px;
    position: relative;
    margin-top: 40px;
    padding: 0;

    > .breakout {
      display: none;
    }

    .services-switcher {
      h1 {
        padding: 25px 0;
        width: 100%;
        font-size: $global-large-font-size;

        img {
          margin-left: 0;
        }
      }

      h6 {
        margin-top: 0;
        padding-top: 150px;
      }
    }
  }
}
