.rotator {
  margin-top: 50px;
  position: relative;

  .rotator-letter {
    font-size: $global-xxxlarge-font-size;
    color: $global-blue;
  }

  span {
    display: inline-block;
  }

  .rotator-seperator {
    width: 25px;
    height: 125px;
    background: $global-muted-background;
    transform: skewY(-25deg) rotate(25deg);
    margin: 0 -18px -32px -12px;
    position: relative;
    z-index: -1;
  }

  .rotator-text {
    margin-top: 40px;
    max-width: 655px;
  }

  .rotator-buttons {
    margin-top: 130px;

    button {
      margin-right: 20px;
    }
  }

  .social-icons {
    position: absolute;
    right: 0;
    top: 110px;

    li {
      margin-bottom: 40px;
    }
  }

  @media screen and (max-width: $container-max-width) {
    .social-icons {
      display: none;
    }

    h1 {
      font-size: $global-xlarge-font-size;
    }
  }

  @media screen and (max-width: $container-small-max-width) {
    .social-icons {
      display: none;
    }

    h1 {
      font-size: $global-xxlarge-font-size;
    }

    .rotator-seperator {
      display: none;
    }

    .word-wrapper {
      display: block;
    }

    .rotator-buttons {
      text-align: center;
      margin-top: 80px;

      button {
        margin: 0;
        padding: 5px 20px;
      }

      a {
        display: none;
      }
    }
  }

  @media screen and (max-width: $container-xsmall-max-width) {
    h1 {
      font-size: $global-xlarge-font-size;
    }

    h3 {
      font-size: $global-mobilelarge-font-size;
    }
  }
}
