#mobile-services-menu {
  width: 315px;
  margin: 80px auto 0 auto;
  position: relative;

  select {
    width: 100%;
    height: 45px;
    margin: 8px 0;
    border: 0;
    border: 1px solid $global-black;
    padding-left: 10px;
    background: $global-white;
    color: $global-black;
    appearance: none;
    font-weight: 300;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #000;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
  }
}

@media screen and (min-width: $container-small-max-width) {
  #mobile-services-menu {
    display: none;
  }
}
