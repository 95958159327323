.uk-navbar {
  li {
    text-transform: uppercase;
    font-weight: $navbar-font-weight;

    &.nav-social-icon {
      display: none;
    }
  }

  .uk-navbar-nav > .logo > a {
    padding: 0;
  }

  .uk-navbar-right {
    &.main-menu {
      li {
        @media screen and (max-width: $container-max-width) {
          button {
            display: none;
          }
        }
      }

      @media screen and (max-width: $container-small-max-width) {
        display: none;
      }
    }

    &.mobile-menu {
      .mobile-menu-icon {
        background-image: url("theme/images/mobile-menu.svg");
        background-repeat: no-repeat;
        background-position: center center;
        height: 30px;
        width: 30px;

        &.close {
          background: url("theme/images/mobile-close.svg") center center no-repeat;
        }
      }

      @media screen and (min-width: $container-small-max-width) {
        display: none;
      }
    }
  }

  @include hook-navbar;
}

.uk-sticky-fixed {
  background: $global-white;
}

.uk-navbar-sticky {
  background: $global-white;

  li.nav-social-icon {
    width: 35px;
    height: 35px;
    display: inline-block;

    > a {
      padding: 0 10px;
    }

    @media screen and (max-width: $container-max-width) {
      display: none;
    }
  }
}

.uk-open {
  background: $global-muted-background;

  @include hook-navbar-nav-item-hover;
}

.uk-navbar-dropdown-nav {
  font-size: $navbar-nav-item-font-size;

  li {
    padding: 5px 10px;

    a {
      color: $dropdown-nav-item-color !important;
    }
  }

  @include hook-navbar-dropdown-nav;
}

#mobile-nav {
  background: $global-black;
  display: none;

  &.active {
    display: block;
  }

  ul {
    padding: 30px 0 0 0;

    li {
      padding-bottom: 30px;

      a {
        text-align: center;
        display: block;
        color: $global-white;
        font-weight: 500;
        text-transform: uppercase;
        font-family: $base-heading-font-family;
        font-size: $global-medium-font-size;

        &.mobile-cta {
          color: $global-white;
          background: $global-blue;
          background-image: linear-gradient(40deg, rgba(43, 75, 214, 0.5) 0%, rgba(119, 144, 255, 0.5) 100%);
          padding: 15px 0;
        }
      }
    }
  }

  .mobile-social-icons {
    ul {
      display: flex;
      display: flex;
      padding: 0;
      margin: 0;
      justify-content: center;
    }

    .nav-social-icon {
      padding: 0 25px;
    }
  }

  @media screen and (min-width: $container-small-max-width) {
    display: none;
  }
}
