#portfolio {
  padding-bottom: 0;

  button.button-dark {
    border: 2px solid $global-black;
    color: $global-black;
  }

  .portfolio-showcase.component {
    .portfolio-images-wrapper {
      position: relative;
      margin-top: 40px;

      .uk-card {
        > img {
          width: 100%;
          height: auto;
          opacity: 0.5;
        }
      }

      .image-info-background {
        background: $global-black;
        position: absolute;
        width: 500px;
        height: 120px;
        z-index: 1;
        top: 60px;
        left: -10px;
        opacity: 0;
      }

      .image-info {
        position: absolute;
        top: 60px;
        left: -10px;
        z-index: 1;
        padding: 5px 70px 0;
      }

      p {
        display: none;
        color: $global-white;
        font-size: $global-medium-font-size;
        position: absolute;
        z-index: 1;
        top: 180px;
        left: -10px;
        z-index: 1;
        padding: 5px 70px 0;
      }

      h3 {
        margin: 20px 0 0 0;
        color: $global-white;
        line-height: 25px;
      }

      .image-info-list {
        font-size: $global-small-font-size;
        margin-top: 0;
        letter-spacing: 0.5px;

        li {
          display: inline-block;
          color: $global-white;
          text-transform: uppercase;
          font-weight: 500;
        }

        .seperator {
          width: 2px;
          height: 10px;
          background: $global-blue;
          transform: skewY(-25deg) rotate(25deg);
          vertical-align: middle;
          display: inline-block;
          margin: 0 3px;
        }
      }
    }

    .uk-card {
      padding: 0;
      flex: 0 1 50%;
      background: none;

      .portfolio-icons {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
      }

      button {
        position: absolute;
        bottom: 45px;
        left: 35px;
        z-index: 1;
        display: none;
      }

      &:hover {
        .image-info-background {
          animation: slide-in-left 200ms ease-in-out;
          display: block;
          left: -10px;
          opacity: 1;
        }

        p {
          display: block;
        }

        > img {
          opacity: 0.2;
        }

        button {
          display: block;
        }
      }
    }

    .portfolio-load-more {
      margin-bottom: 0;
    }

    @media screen and (max-width: $container-small-max-width) {
      .portfolio-text {
        text-align: center;
      }

      .portfolio-images-wrapper {
        flex-wrap: wrap;

        .uk-card {
          flex: 0 1 100%;
          margin-left: 0 !important;

          &:first-child {
            margin-bottom: 40px;
          }

          .image-info-wrapper > p {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: $container-small-max-width) {
      padding-top: 200px;

      .portfolio-images-wrapper {
        .image-info-background {
          width: 95%;
        }

        .image-info {
          padding: 5px 20px 0;

          h3 {
            font-size: $global-mobilelarge-font-size;
            white-space: normal;
          }

          .image-info-list {
            font-size: $global-xsmall-font-size;
          }
        }
      }
    }

    @media screen and (max-width: $container-xsmall-max-width) {
      padding-top: 0px;

      .portfolio-showcase.component {
        .portfolio-images-wrapper {
          .image-info-list {
            font-size: $global-xsmall-font-size;
          }
        }
      }
    }
  }

  &.uk-modal-full {
    .uk-modal-dialog {
      height: 100vh;

      .uk-slideshow-items {
        text-align: center;
      }
    }
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    left: -200px;
    opacity: 0;
  }

  100% {
    left: -10px;
    opacity: 1;
  }
}
