.cta-bar {
  background: $global-blue;
  text-align: center;

  a {
    color: $global-white;
    font-size: $global-medium-font-size;
    text-transform: uppercase;
    display: inline-block;
    padding: 30px 0;
    font-weight: bold;

    svg {
      width: 25px;
      height: 25px;
    }

    span {
      margin-left: 5px;
    }

    &:hover {
      color: $global-white;
    }
  }
}
