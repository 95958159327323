.stripes {
  position: absolute;
  left: 0;
  display: grid;
  grid: 400px 600px / 1fr;
  background: $global-white;
  transform: skewY(-12deg);
  z-index: -1;
  width: 100%;

  :nth-child(2) {
    grid-area: 2/span 1/span;
    background: $global-black;
  }

  @media screen and (max-width: $container-small-max-width) {
    :nth-child(2) {
      display: none;
    }
  }
}
