//Colors
$global-muted-background: #f1f1f1;
$global-black: black;
$global-color: $global-black;
$global-emphasis-color: $global-black;
$global-white: white;
$global-blue: #2b4bd6;
$global-primary-background: $global-blue;
$global-muted-color: #c4c4c4;
$global-grey: #cfcfcf;
$global-grey-2: #4a4a4a;
$global-grey-3: #979797;
$global-faded: #9b9b9b;
$dropdown-nav-item-color: #727272;
$text-decoration-color: $global-blue;

//Fonts
$global-font-family: "Lato", sans-serif;
$global-font-size: 20px;
$base-heading-font-family: "Montserrat", sans-serif;
$base-heading-text-transform: uppercase;
$base-heading-font-weight: bold;
$base-body-font-weight: 300;
$global-mobilelarge-font-size: 1.5rem;
$global-large-font-size: 1.8rem;
$global-xlarge-font-size: 2.2rem;
$global-xxlarge-font-size: 3.2rem;
$global-xxxlarge-font-size: 4.5rem;
$global-medium-large-font-size: 1.6rem;
$global-medium-font-size: 0.75rem;
$global-small-font-size: 0.6rem;
$global-xsmall-font-size: 0.5rem;
$base-h6-font-size: 16px;

//Navbar
$navbar-background: $global-white;
$navbar-nav-item-font-size: 0.6rem;
$navbar-nav-item-color: $global-black;
$navbar-nav-item-padding-horizontal: 25px;
$navbar-nav-item-height: 60px;
$navbar-dropdown-padding: 0 15px 15px;
$navbar-font-weight: normal;
$navbar-dropdown-width: 160px;

//Subnav
$subnav-pill-item-hover-background: transparent;
$subnav-pill-item-hover-color: $global-blue;
$subnav-pill-item-color: $global-white;

//Buttons
$button-font-size: 0.6rem;
$button-default-background: transparent;
$button-border: 2px solid $global-black;
$button-line-height: 30px;
$button-padding-horizontal: 15px;
$button-primary-background: $global-blue;
$button-primary-color: $global-white;
$button-secondary-background: transparent;
$button-secondary-hover-background: $global-grey-2;
$button-secondary-color: $global-white;
$button-text-color: $global-black;
$button-text-hover-color: $global-color;

//Misc
$global-border: 2px solid $global-black;
$global-control-small-height: 36px;
$container-xsmall-max-width: 600px;
