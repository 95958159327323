.component.testimonials-switcher {
  padding-top: 0;

  .switcher-quote-content-wrapper {
    position: relative;
    margin: 90px auto 80px auto;

    .switcher-quote {
      position: absolute;
      background: $global-black;
      font-weight: bold;
      color: $global-white;
      font-size: $global-large-font-size;
      font-family: $base-heading-font-family;
      font-size: 5rem;
      height: 60px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-bottom: -40px;
      }

      &.quote-open {
        top: -30px;
        left: -30px;
      }

      &.quote-close {
        right: -30px;
        bottom: -30px;
      }
    }
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 30px solid $global-muted-background;
    position: absolute;
    bottom: -28px;
    left: 50%;
  }

  .switcher-content {
    position: relative;
    max-width: 880px;
    margin: 0 auto;
  }

  .switcher-text {
    font-size: 0.9rem;
    font-style: italic;
    padding: 60px 75px;
    background-color: $global-muted-background;
    max-width: 730px;
    line-height: 32px;
    border-radius: 16px;
    margin-bottom: 60px;
  }

  .testimonial-people {
    display: flex;
    justify-content: center;

    .testimonial-person {
      flex: 0 1 33.3%;
      padding: 0;
      text-align: center;
      text-transform: uppercase;

      a {
        display: flex;
        justify-content: center;
      }

      img {
        border-radius: 50%;
        filter: grayscale(100%);
        max-width: 100px;
      }

      .person-info {
        text-align: left;
        margin: 30px 0 0 -15px;
        position: relative;
      }

      .person-name {
        color: $global-faded;
        margin: 0;
        font-size: $global-medium-font-size;
        font-weight: bold;
        padding: 2px 20px;
      }

      .person-company {
        color: $global-muted-color;
        font-size: $global-small-font-size;
        font-weight: 500;
        padding: 2px 20px;
        white-space: nowrap;
      }

      .person-website {
        color: $global-color;
        font-size: $global-small-font-size;
        font-weight: 500;
        padding: 2px 20px;
        text-transform: none;
      }

      &.uk-active {
        img {
          filter: grayscale(0%);
        }

        a {
          background: none;
        }

        .person-name {
          color: $global-white;
          background: $global-black;
        }

        .person-company {
          color: $global-blue;
        }
      }
    }
  }

  .mobile-switcher-controls {
    position: absolute;
    bottom: 45px;
    height: 25px;
    width: 100%;
    display: none;

    a {
      position: absolute;
      color: $dropdown-nav-item-color;
      cursor: pointer;

      &.control-left {
        left: 0;
      }

      &.control-right {
        right: 0;
      }
    }
  }

  @media screen and (max-width: $container-small-max-width) {
    .testimonial-people {
      .testimonial-person {
        display: none;
        flex: 0 1 100%;

        &.uk-active {
          display: block;
        }
      }
    }

    .mobile-switcher-controls {
      display: block;
    }

    .switcher-quote-content-wrapper .switcher-quote {
      &.quote-open {
        left: 0;
      }

      &.quote-close {
        right: 0;
      }
    }
  }
}
