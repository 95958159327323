html,
body {
  overflow-x: hidden;
}

// 1. Your custom variables and variable overwrites.
@import "theme/variables.scss";

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables.scss";
@import "uikit/src/scss/mixins.scss";

// 3. Your custom mixin overwrites.

// Components
@import "theme/components/navbar.scss";
@import "theme/components/button.scss";
@import "theme/components/stripes.scss";
@import "theme/components/rotator.scss";
@import "theme/components/services-switcher.scss";
@import "theme/components/portfolio-showcase.scss";
@import "theme/components/stats-list.scss";
@import "theme/components/testimonials-switcher.scss";
@import "theme/components/footer.scss";
@import "theme/components/modal.scss";
@import "theme/components/cta-bar.scss";
@import "theme/components/services-page.scss";
@import "theme/components/mobile-services-menu.scss";

// 4. Import UIkit
@import "uikit/src/scss/uikit.scss";

.breakout {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 960px;

  div {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.uk-background-cover {
  background-color: $global-black;
  background-image: url(./theme/images/hipster-background.jpg);
  background-position-y: bottom;
}

section.uk-section {
  @media screen and (max-width: $container-small-max-width) {
    padding-bottom: 0;
  }
}

.overlay {
  background: $global-black;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.9;
}

.underlay {
  background: $global-black;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.component {
  padding-top: 100px;

  h6 {
    text-align: center;
    font-weight: 500;
  }

  p {
    max-width: 1051px;
    margin: auto;
  }

  &.mobile-component {
    display: none;
    padding: 0;
    position: absolute;

    .breakout {
      height: 600px;
    }

    @media screen and (max-width: $container-small-max-width) {
      display: block !important;
    }
  }
}

img {
  &.dropdown-icon {
    width: 8px;
    height: 8px;
    padding-left: 5px;
  }

  &.social-icon {
    width: 20px;
    height: 20px;
  }

  &.services-icon {
    width: 51px;
    height: 51px;
    vertical-align: baseline;
  }

  &.portfolio-icon {
    width: 25px;
    height: 25px;
  }

  &.stats-icon {
    width: 82px;
    height: 65px;
    margin-bottom: 10px;
  }

  &.footer-icon {
    margin-right: 10px;
  }
}

a:hover {
  text-decoration: underline;
  text-decoration-color: $text-decoration-color;
}
